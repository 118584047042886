<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Nickname"
              v-model="filter.nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="options.statuses"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="onFilter(filter)">
            <i class="flaticon2-search-1"></i> Show
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table :data="currentProjectDetails" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Project Code</span>
              </th>
              <th style="min-width: 100px">Project Name</th>
              <th style="min-width: 100px">Nickname</th>
              <th style="min-width: 150px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.nickname }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toTransmittalList(item)"
                  title="List Transmittal"
                >
                  <i class="flaticon-book"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FILTER_PROJECT_DETAIL,
  POST_LOAD_PROJECT
} from "@/core/services/store/project.module";
import { mapGetters } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";

export default {
  name: "transmittal",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        nickname: null,
        status: null
      },
      currentItem: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN"
          }
        ]
      },
      loadingActive: false,
      tableMainProject: true
    };
  },
  methods: {
    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    onReloadProject() {
      const submitButton = this.$refs["kt_load_project"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store.dispatch(POST_LOAD_PROJECT);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 127377);
    },
    showDeleteModal(data) {
      this.currentItem = data;
      this.$refs["delete"].show();
    },

    toTransmittalList(data) {
      this.$router.push({
        name: "transmittal-list",
        params: {
          id: data.code
        }
      });
    },

    onFilter(param) {
      const filter = {
        jobdesk: "interface",
        params: {
          nickname: param.nickname === null ? "" : param.nickname,
          status: param.status === null ? "" : param.status,
          jobdesk: [
            "interface",
            "design",
            "planner",
            "drafter",
            "koor",
            "enginer",
            "manager",
            "admin"
          ]
        }
      };
      this.$store.dispatch(FILTER_PROJECT_DETAIL, filter);
    }
  },
  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Transmittal" }]);
    this.onFilter(this.filter);

    this.loadingActive = true;
    this.tableMainProject = false;
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentMenus",
      "currentProjectDetails",
      "currentLoadingProject"
    ]),

    loading() {
      return this.currentLoadingProject;
    }
  }
};
</script>

<style></style>
